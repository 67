//@import "core/_bootstrap-variables";
@import "_variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// @import "node_modules/redactor-js/redactor/redactor" !force;

body {
  padding: 70px 10px 30px 10px;
}

.nav-tabs {
  margin-bottom: 30px;
}